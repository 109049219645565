* {
    /*font-family: var(--font);*/
    font-family: 'OpenSans', sans-serif;
    box-sizing: border-box;
    outline: none;
}

body {
    cursor: default;
    margin: 0;
    /*direction: rtl;*/
}

[dir=rtl] body{
    direction: rtl;
}

img,
iframe {
    max-width: 100%;
}

a[href] {
    text-decoration: none;
    cursor: pointer;
}

button {
    cursor: pointer;
}

textarea {
    resize: none;
}

.change-lang{
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: 0;
    color: #fff;
}