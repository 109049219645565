.content {
    padding: 30px;
    /*background-color: var(--light-gray);*/
    /*background-image: url("../media/images/logo3.png");*/
    min-height: 100vh;
    display: flex;
    /*justify-content: center;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.d-flex {
    display: flex;
}

.jcc {
    justify-content: center;
}

.mt40 {
    margin-top: 40px;
}

.mt30 {
    margin-top: 30px;
}

.mt60 {
    margin-top: 60px;
}

.challenge_icon {
    display: block;
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
    color: white;
    border: 0;
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
}

.progress_badge {
    display: flex;
}

.badge {
    margin: 20px 0 0;
    min-width: 100px;
}

.badge img {
    margin: 0 auto;
    display: block;
    max-width: 100%;
}