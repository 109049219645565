/*Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) {
    /*quiz*/
    .quiz_title {
        font-size: 23px;
    }

    .quiz_desc, .quiz_desc p, .quiz_desc span {
        font-size: 16px !important;
    }

    /*question*/
    .question_title {
        font-size: 17px;
        font-weight: bold;
    }

    .question_note {
        font-size: 15px;
    }

    .question_num_stars {
        font-size: 14px;
    }


    /*checkbox*/
    .check-label {
        font-size: 16px;
    }


    /*progress*/
    .progress {
        margin-left: 10px;
    }

    .progress_bar i {
        font-size: 25px;
    }

    /*button*/
    .main-btn.big {
        min-width: 180px;
        height: 45px;
        line-height: 45px;
    }

    /*popup*/
    .popup__1--title {
        font-size: 31px;
    }

    .popup__1--title1 {
        font-size: 70px;
    }

    .popup__1--title1.small-title {
        font-size: 45px;
    }

    .popup__form-btn {
        width: 120px;
        height: 42px;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (max-width: 991px) {
    /*main*/
    .reverse991 {
        flex-direction: column-reverse;
    }

    /*badge progress*/
    .progress_badge {
        margin-bottom: 30px;
    }

    .progress {
        flex: unset;
        display: flex;
        align-items: center;
    }

    .progress_bar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: initial;
    }

    .progress_bar__row {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
    }

    .progress_bar i {
        font-size: 23px;
        float: unset;
    }


    .progress_bar i:nth-child(odd) {
        clear: unset;
    }

    .badge {
        margin: 0;
    }

    /*quiz*/
    .quiz_content.mod {
        margin-top: 60px;
    }

    .quiz_logo {
        width: 170px;
    }

    .quiz_title {
        font-size: 18px;
    }

    /*buttons*/
    .main-btn.big {
        min-width: 160px;
        height: 40px;
        line-height: 40px;
    }

    /*popup*/
    .popup__1--title {
        font-size: 27px;
    }

    .popup__1--title1 {
        font-size: 60px;
    }

    .popup__1--title1.small-title {
        font-size: 40px;
    }

    .popup__1--body--desc {
        font-size: 17px;
    }
}

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) {
    /*main*/
    .content {
        padding: 0;
    }

    /*quiz*/
    .quiz {
        border-radius: 0;
        padding: 25px 10px;
    }

    .quiz_title {
        font-size: 17px;
    }

    /*question*/
    .question_title {
        font-size: 16px;
    }

    .question_num {
        margin-left: 10px;
        height: 30px;
        width: 30px;
    }

    .question_note {
        font-size: 14px;
    }

    /*checkbox*/
    .check-checkmark {
        height: 30px;
        width: 30px;
    }

    .check-label {
        font-size: 15px;
        padding: 15px 15px 15px 55px;
    }

    [dir=rtl] .check-label {
        padding: 15px 55px 15px 15px;
    }

    /*buttons*/
    .main-btn {
        min-width: 120px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
    }

    .main-btn.big {
        min-width: 140px;
        height: 38px;
        line-height: 38px;
        font-size: 15px;
    }

    /*popup*/
    .popup__1--title {
        font-size: 24px;
    }

    .popup__1--title1 {
        font-size: 45px;
    }

    .popup__1--title1.small-title {
        font-size: 35px;
    }

    .popup__1--body--desc {
        font-size: 16px;
    }

    .popup__1--body.mod {
        max-height: 300px;
    }

    /*item1*/
    .item1-title {
        font-size: 17px;
    }
}

/*Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {
    /*quiz*/
    .quiz_title {
        font-size: 16px;
        padding-right: 15px;
        margin-right: 17px;
    }

    /*popup*/
    .popup__1--head {
        padding: 30px 20px 15px;
    }

    .popup__1--title {
        font-size: 20px;
    }

    .popup__1--title1 {
        font-size: 40px;
    }

    .popup__1--body {
        padding: 0 15px;
        margin: 15px 10px 15px;
    }

    .popup__1--footer {
        margin-top: 10px;
        padding: 10px 20px 30px;
    }

    .popup__form-btn {
        width: 110px;
        height: 38px;
        font-size: 14px;
    }
}

@media (max-width: 490px) {
    .badge {
        min-width: 80px;
    }

    .container {
        padding-right: 10px;
        padding-left: 10px;
    }

    .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
        padding-right: 10px;
        padding-left: 10px;
    }

    .row {
        margin-right: -10px;
        margin-left: -10px;
    }

    .popup__1--title1.small-title {
        font-size: 30px;
    }

}

@media (max-width: 450px) {
    .progress_bar i {
        font-size: 20px;
        margin: 0 3px 4px;
    }
}

@media (max-width: 445px) {
    .question_info > :first-child {
        font-size: 12px;
        margin-right: 0;
        white-space: nowrap;
    }


    .popup__1--body.mod {
        max-height: 240px;
    }

    .popup__1--title2 {
        font-size: 18px;
    }

    .popup__1--head {
        padding: 10px 10px 5px;
    }

    .popup__1 img {
        width: 100px;
    }

    .popup__1--footer {

    }

    .popup__1--footer-desc {
        margin-bottom: 10px;
    }

    .challenge_icon {
        margin-top: 8px;
    }

    .popup__1--body {
        margin: 10px 10px;
    }
}

@media (max-width: 390px) {
    .progress_badge {
        /*margin-right: -10px;*/
        /*margin-left: -10px;*/
    }

    .badge {
        min-width: 60px;
    }

    .progress_bar i {
        font-size: 18px;
    }

}

@media (max-width: 350px) {
    .progress_badge {
        /*margin-right: -20px;*/
        /*margin-left: -20px;*/
    }

    .progress_bar i {
        font-size: 15px;
    }

}

@media (max-width: 318px) {
    .progress_bar i {
        font-size: 14px;
    }

    .badge {
        min-width: 40px;
    }

    .question_num_stars {
        font-size: 12px;
    }

    .popup__1--title1.small-title {
        font-size: 25px;
    }

}

@media (max-width: 280px) {
    .progress_bar i {
        font-size: 12px;
    }

    .badge {
        min-width: 30px;
    }


}

@media (max-width: 250px) {

}