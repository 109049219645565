.share {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.share_title {
    font-size: 15px;
    color: white;
    margin-bottom: 5px;
}

.share_buttons {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none;
    margin: 0 10px 0 0;
}

.share_buttons li {
    /*display: flex;*/
    margin: 0 5px 5px;
    /*flex: 1;*/
}

.share_icon {
    width: 35px;
    height: 35px;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 22px;
    border: 0;
}

.share_icon.facebook {
    background-color: var(--facebook);
}

.share_icon.whatsapp {
    background-color: var(--whatsapp);
}