.main-btn {
    display: inline-block;
    font-size: 16px;
    border: none;
    /*background-color: var(--main-color);*/
    color: white;
    text-align: center;
    padding: 0 5px;
    min-width: 150px;
    height: 50px;
    line-height: 50px;
    border-radius: var(--border-radius);
    font-weight: bold;
    box-shadow: 0 0 7px var(--blue);
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: white;
    transition: color 0.5s ease-in-out;
    transition-delay: 0.1s;
}

.main-btn.big {
    min-width: 250px;
}

.main-btn::after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    bottom: 0;
    right: 0;
    height: calc(100% + 100px);
    width: 100%;
    transition: height 0.5s ease-in-out;
    border-radius: 50% 50% 0 0/75% 75% 0 0;
    background-color: #084973;
}

.main-btn.is-loading::after,
.main-btn:hover::after {
    height: 0;
}


.main-btn:hover {
    color: var(--blue);
}

.main-btn[disabed],
.main-btn.disabled {
    /*box-shadow*/
    /*pointer-events: none;*/
    /*cursor: pointer;*/
    /*box-shadow: 0 0 7px gray;*/

}

.main-btn[disabed]::after,
.main-btn.disabled::after {
    /*background-color: gray;*/

}
