.question-head {
    padding: 15px;
    box-shadow: 0 0 10px rgba(89, 89, 88, 0.65098) inset;
    background-color: white;
    border-radius: var(--border-radius);
}

.question_title {
    font-size: 20px;
    color: var(--blue);
    display: flex;
    align-items: baseline;
    line-height: 1.7;
    text-align: justify;
}

.question_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question_info > :first-child {
    margin-right: 43px;
}

.question_num {
    flex: none;
    margin-right: 13px;
    height: 32px;
    width: 32px;
    border: 1px solid white;
    border-radius: 10px;
    display: block;
    line-height: 30px;
    text-align: center;
    color: white;
    background-color: var(--blue);
    border: 1px solid var(--blue);
}

[dir=rtl] .question_num {
    font-family: "GESSTwoMedium" !important;
    margin-left: 13px;
    margin-right: 0;
}

.question_num_stars {
    font-size: 15px;
    font-weight: bold;
    color: var(--main-color);
    display: block;
    text-align: right;
    margin-top: 10px;
}

[dir=rtl] .question_num_stars {
    text-align: left;
}

.question_num_stars i {
    color: var(--orange);
    margin-left: 5px;
}

[dir=rtl] .question_num_stars i {
    margin-right: 5px;
    margin-left: 0;
}

.question_note {
    font-weight: bold;
    font-size: 17px;
    color: var(--red);
    margin-top: 10px;
}

.answer {
    margin-top: 20px;
}

.answer_title {
    font-size: 18px;
    font-weight: 600;
    color: var(--blue);
}

.answer_list {
    padding: 0;
    list-style: none;
    margin: 10px 0 0;
}

.answer_list li + li {
    margin-top: 10px;
}