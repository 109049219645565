.check {
    /*background-color: #e1e1e1;*/
    box-shadow: 0 0 10px #595958a6 inset;
    /*background-color: var(--light-blue);*/
    border-radius: var(--border-radius);
}

.check-label {
    display: block;
    position: relative;
    color: white;
    font-size: 18px;
    line-height: 1.7;
    padding: 15px 15px 15px 60px;
    z-index: 1;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.3s ease;
    text-align: justify;
}

[dir=rtl] .check-label {
    padding: 15px 60px 15px 15px;
}

/*li:focus .check-label,*/
.check:hover .check-label {
    color: white;
    background-color: var(--blue);
}

.check-label.true {
    background-color: var(--green);
}

.check-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.check-checkmark {
    position: absolute;
    top: 15px;
    left: 15px;
    height: 32px;
    width: 32px;
    border: 1px solid var(--blue);
    border-radius: 10px;
    background-color: white;


    line-height: 30px;
    text-align: center;
    color: var(--blue);
    /*font-weight: bold;*/
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "GESSTwoMedium" !important;

}

[dir=rtl] .check-checkmark {
    right: 15px;
    left: initial;
}

.check-checkmark::before {
    /*display: block;*/
    /*content: attr(data-title);*/
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: var(--blue);
    font-weight: bold;
}

.check-label.is-checked,
.check-input:checked ~ .check-label {
    color: white;
    background-color: var(--blue);
}

[dir=ltr] .answer_list > li .check-checkmark {
    padding-top: 2px;
}

[dir=rtl] .answer_list > li:first-child .check-checkmark {
    padding-top: 5px;
    font-size: 20px;
}