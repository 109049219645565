@font-face {
    font-family: 'GESSTwoMedium';
    src: url('./GESSTwoMedium-Medium.eot');
    src: url('./GESSTwoMedium-Medium.eot?#iefix') format('embedded-opentype'),
    url('./GESSTwoMedium-Medium.woff2') format('woff2'),
    url('./GESSTwoMedium-Medium.woff') format('woff'),
    url('./GESSTwoMedium-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    /*unicode-range: U+0000-00FF, U+06F0-06F9;*/
}