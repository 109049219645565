.quiz {
    padding: 40px;
    /*border: 1px solid var(--blue);*/
    /*background-image: url("../media/images/logo3.png");*/
    border-radius: var(--border-radius);

    box-shadow: 0 10px 41px -26px var(--blue);
    position: relative;
    /*background-color: rgba(247, 247, 247, 0.95);*/
    /*background: linear-gradient(to bottom, var(--blue), var(--light-blue));*/
    background: linear-gradient(to bottom, var(--blue) 20%, var(--light-blue) 110%);
    flex: 1;
    overflow: hidden;
    z-index: 1;
}

.quiz::before {
    content: url("../../images/header_bg.png");
    position: absolute;
    bottom: -10px;
    left: -20px;
    /*transform: rotate(-45deg);*/
    /*opacity: 0.1;*/
    z-index: -1;
}

/*.quiz::before {*/
/*    content: url("../media/images/logo3.png");*/
/*    position: absolute;*/
/*}*/

.quiz-head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /*min-height: 130px;*/
    /*padding: 0 260px;*/
}

.quiz_title {
    flex: none;
    font-size: 27px;
    font-weight: 600;
    color: white;
    text-align: center;
    padding-left: 30px;
    margin-left: 30px;
    position: relative;
}

[dir=rtl] .quiz_title {
    padding-left: 0;
    margin-left: 0;

    padding-right: 30px;
    margin-right: 30px;
}

.quiz_title::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    height: calc(100% + 20px);
    transform: translateY(-50%);
    width: 1px;
    border-radius: 20px;
    box-shadow: 2px 0 3px white;
}

[dir=rtl] .quiz_title::before {
    right: 0;
    left: initial;
}

.quiz_logo img {
    display: block;
}


.quiz_content {
    margin-top: 40px;
}

.quiz_content.mod {
    margin-top: 100px;
}

.quiz_desc {
    margin-top: 20px;
}

.quiz_desc,
.quiz_desc p,
.quiz_desc span {
    /*font-family: var(--font) !important;*/
    font-size: 18px !important;
    color: white !important;
    text-align: justify !important;
}

.quiz_desc p:first-child {
    margin-top: 0;
}

.quiz_desc a {
    color: white;
    font-weight: bold;
    transition: color 0.3s ease;
}

.quiz_desc a:hover {
    color: var(--blue);
}

.quiz_more {
    margin-top: 60px;
}

.quiz_more.center {
    text-align: center;
}

.quiz_num {
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    color: var(--blue);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

[dir=rtl] .quiz_num {
    font-family: "GESSTwoMedium" !important;
    text-align: left;
}

.quiz_num__current {
    font-family: inherit;
}

.quiz_num__limit {
    font-family: inherit;
}

.quiz_desc_more {
    color: var(--blue);
    font-weight: bold;
    cursor: pointer !important;
}

.quiz_desc_more:focus {
    text-decoration: underline;
}