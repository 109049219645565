.progress {
    /*margin-top: 30px;*/
    flex: none;
    margin-left: 20px;
}

/*.progress_bar {*/
/*    margin: 0 -5px;*/
/*background-color: var(--light-blue);*/
/*padding: 8px 5px;*/
/*border-radius: var(--border-radius);*/
/*position: relative;*/
/*display: flex;*/
/*flex-wrap: wrap;*/
/*flex-direction: column;*/
/*justify-content: center;*/
/*align-items: center;*/
/*}*/
.progress_bar {
    width: 80px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.progress_bar__row {

}

.progress_bar i {
    display: block;
    margin: 0 5px 4px;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.2);
    float: right;
}

.progress_bar i:nth-child(odd) {
    clear: both;
}

/*.progress_bar i.easy {*/
/*    color: var(--green);*/
/*}*/

/*.progress_bar i.med {*/
/*    color: var(--orange);*/
/*}*/

/*.progress_bar i.hard {*/
/*    color: var(--red);*/
/*}*/
.progress_bar i.animate {
    animation: heartbeat 1s;
}

.progress_bar i.won {
    opacity: 1;
    color: #f9c710;
}

@keyframes heartbeat {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.2, 1.2);
    }
    100% {
        transform: scale(1, 1);
    }
}

/*.progress_bar .bar {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    right: 5px;*/
/*    height: 5px;*/
/*    border-radius: var(--border-radius);*/
/*    background-color: var(--blue);*/
/*    width: 10%;*/
/*    transform: translateY(-50%);*/
/*}*/

/*.progress_bar .bar::before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: -50%;*/
/*    left: 0;*/
/*    width: 10px;*/
/*    height: 10px;*/
/*    border-radius: 50%;*/
/*    background-color: var(--blue);*/
/*}*/