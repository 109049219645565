.item1 {
    /*border: 1px solid var(--light-gray);*/
    border-radius: var(--border-radius);
    background-color: transparent;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
    padding: 30px;
    flex: 1;
    margin-bottom: 20px;
}

.item1-image img {
    display: block;
    margin: 0 auto;
}

.item1-title {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    color: var(--blue);
    margin-top: 15px;
}

.item1-desc {
    margin-top: 15px;
}

.item1-desc,
.item1-desc p,
.item1-desc span {
    font-family: var(--font) !important;
    font-size: 17px !important;
    color: var(--blue) !important;
    text-align: center !important;
}

.item1-desc p:first-child {
    margin-top: 0;
}