:root {
    --main-color: #5693c9;
    --dark-blue: #1c4980;
    --light-blue: #cce3f1;
    --blue: #006fb7;

    --green: #8fd04e;
    --orange: #f9c710;
    --red: #df7609;

    --gray: #595959;
    --med-gray: #7a7a7a;
    --light-gray: rgb(247, 247, 247);
    --light-gray2: rgb(227, 227, 227);

    --border-radius: 15px;

    --facebook: #3b5998;
    --whatsapp: #00e676;;
    /*--font: "Arabic Transparent","Simplified Arabic","Times New Roman",arial,verdana,sans-serif!important;*/
    --font: 'OpenSans', sans-serif !important;
}