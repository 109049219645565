.popup__1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 700;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);

    display: none;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.popup__1.is-visible {
    display: block;
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
}

.popup__1--main-body {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    position: relative;
}

.popup__1--close {
    color: white;
    font-size: 18px;
    position: absolute;
    top: 17px;
    right: 25px;
    transition: all 0.2s linear;
}

.popup__1--close:hover {
    cursor: pointer;
    color: #a90000;
}

.popup__1--container {
    width: 550px;
    max-width: calc(100% - 15px);
    max-height: calc(100% - 15px);
    border-radius: 25px;
    overflow-x: hidden;
    overflow-y: auto;
    /*background-color: #fff;*/
    background: linear-gradient(var(--light-blue) 0, var(--blue) 50%);
}

.popup__1--container::-webkit-scrollbar {
    width: 7px;
    border-radius: 5px;
}

.popup__1--container::-webkit-scrollbar-thumb {
    background: var(--light-gray2);
    border-radius: 5px;
}

.popup__1--container::-webkit-scrollbar-track {
    background: gray;
    border-radius: 5px;
}

.popup__1--head {
    padding: 40px 40px 15px;
    position: relative;
}

.popup__1--title {
    font-size: 35px;
    /*font-weight: bold;*/
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.popup__1--title1 {
    display: block;
    font-size: 90px;
}

.popup__1--title1.small-title{
    font-size: 45px;
}

.popup__1--title2 {
    display: block;
    font-size: 22px;
    color: white;
    text-align: center;
    text-transform: uppercase;
}

.popup__1--title__link {
    padding: 0;
    background-color: transparent;
    border: 0;
    font: inherit;
    color: inherit;
    text-decoration: underline;
}

.popup__1--footer__link {
    padding: 0;
    background-color: transparent;
    border: 0;
    font: inherit;
    color: #fff;
    text-decoration: underline;
    text-align: center;
    margin: 7px auto 0;
    display: block;
}

.popup__1--body {
    padding: 0 30px;
    margin: 20px 10px 15px;
}

.popup__1--body.mod {
    max-height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.popup__1--body.mod::-webkit-scrollbar {
    width: 7px;
    border-radius: 5px;
}

.popup__1--body.mod::-webkit-scrollbar-thumb {
    background: var(--light-gray2);
    border-radius: 5px;
}

.popup__1--body.mod::-webkit-scrollbar-track {
    background: gray;
    border-radius: 5px;
}

.popup__1--body--title {
    font-size: 17px;
    /*font-weight: bold;*/
    color: white;
}

.popup__1--body--desc {
    /*margin-top: 15px;*/
    font-size: 18px;
    /*font-weight: bold;*/
    text-align: justify;
    color: white;
    line-height: 1.7;
}

.popup__1--body--desc a {
    color: #fff;
    text-decoration: underline;
}

.popup__1--body--desc2 {
    font-size: 16px;
    text-align: justify;
    text-align-last: center;
    color: white;
    margin-top: 15px;
}

.popup__1--body--desc3 {
    font-size: 16px;
    text-align: justify;
    text-align-last: center;
    color: white;
    margin-top: 15px;
}

.popup__1--body--desc2 p {
    margin: 0;
}

.popup__group {
    position: relative;
    margin-bottom: 30px;
}

.popup__1--footer {
    margin-top: 20px;
    padding: 10px 40px 40px;
}

.popup__1--footer-desc {
    text-align: center;
    display: block;
    margin-bottom: 15px;
    color: #fff;
}

.popup__form-btn {
    width: 130px;
    height: 45px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--dark-blue);
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border: 0;
    border-radius: var(--border-radius);
    transition: all 0.2s linear;
}

.popup__form-btn.disabled {
    background-color: gray;
    pointer-events: none;
}

.popup__form-btn.not-clickable {
    pointer-events: none;
}

/*.popup__form-btn:hover {*/
/*    background-color: white;*/
/*    color: var(--blue);*/
/*}*/

.popup__group--images {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0 -20px;
}

.popup__group--img {
    padding: 0 20px;
}

.popup__1--body--img img {
    display: block;
    margin: 0 auto;
}

.popup__1 img {
    height: auto !important;
    max-width: 100% !important;
}

.popup__1--body--desc img {
    display: block;
    margin: 0 auto;
}